import React, { FC, useCallback, useState } from "react";
import { Modal } from "../modal";
import { Header, Footer, Content } from "../modal/style";
import { FormattedMessage } from "react-intl";
import { PaymentMethod } from "../../types/payment-method";
import Button, { BUTTON_TYPE } from "../../ui/button";
import { ButtonAction, ButtonContainer, Text } from "./style";
import { deleteAccount } from "../../store/user/actions";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { TOAST_TYPE, useToast } from "../../ui/toast";
import Spinner from "../../images/spinner.svg";
import { signOut } from "../../store/auth/actions";
import { navigate } from "gatsby";

interface DeleteAccountModalProps {
  closeModal: () => void;
  onSuccess: (paymentMethod: PaymentMethod) => void;
  open: boolean;
}

const DeleteAccountModal: FC<DeleteAccountModalProps> = ({
  closeModal,
  onSuccess,
  open,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const onDeleteAccount = useCallback(() => {
    (async (): Promise<void> => {
      setLoading(true);
      const response = await dispatch(deleteAccount());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      } else {
        dispatch(signOut());
        navigate("/");
      }
      setLoading(false);
    })();
  }, []);
  return (
    <Modal open={open} closeModal={closeModal}>
      <Header>
        <FormattedMessage id="profile.delAccount" />
      </Header>
      <Content>
        <Text>
          <FormattedMessage id="profile.delAccount.confirmation.text" />
        </Text>
      </Content>

      <Footer>
        <ButtonContainer>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <ButtonAction
                type={BUTTON_TYPE.PRIMARY}
                onClick={closeModal}
                style={{ padding: 10 }}
              >
                <FormattedMessage id="profile.delAccount.not" />
              </ButtonAction>
              <ButtonAction
                type={BUTTON_TYPE.ERROR}
                onClick={onDeleteAccount}
                style={{ padding: 10 }}
              >
                <FormattedMessage id="profile.delAccount.confirmation" />
              </ButtonAction>
            </>
          )}
        </ButtonContainer>
      </Footer>
    </Modal>
  );
};

export default DeleteAccountModal;
