import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const Container = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 25px;
`

export const TitleContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

export const LinkContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  display: flex;
  width: 210px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 2px grey;
  margin-bottom: 30px;

  @media(min-width: 440px) {
    width: 250px;
  }
`

export const SvgContainer = styled.div`
  margin-right: 10px;
`

export const Text = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  text-decoration: none;
`
export const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
`
export const Title = styled(Text)`
  font-size: 18px;
  font-weight: bold;
`

export const Button = styled.button`
  border-radius: 20px;
  color: ${({ theme: { colors } }): string => colors.white};
  font-size: 15px;
  border: none;
  padding: 0 15px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  height: 40px;
  cursor: pointer;
`;

export const ProfileContainer = styled.div`
  margin-bottom: 20px;
  width: 90%;
  background-color: ${({ theme: { colors } }): string => colors.white};
  padding: 20px 30px;
  border-radius: 10px;

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    width: 70%;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 50%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.tablet} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  width: 50%;
  font-size: 15px;
`;

export const Label = styled(Content)`
  font-weight: bold;
`;

export const TitlePartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const ContentPartContainer = styled(TitlePartContainer)`
  padding-top: 20px;
  border-top: ${({ theme: { colors } }): string =>
    `1px solid ${colors.border}`};
`;

export const Icon = styled.div`
  width: 24px;
  margin-right: 15px;
`;
