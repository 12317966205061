import React, { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, navigate } from "gatsby";
import { useSelector } from "react-redux";
import Spinner from "../../images/spinner.svg";

import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import {
  Button,
  Container,
  TitleContainer,
  SvgContainer,
  LinkContainer,
  Text,
  Title,
  Wrapper,
  ProfileContainer,
  Label,
  Content,
  InfoContainer,
  TitlePartContainer,
  ContentPartContainer,
  Icon,
  SpinnerContainer,
} from "./style/home";
import PaymentMethodIcon from "../payment-methods/payment-method-icon";
import { signOut } from "../../store/auth/actions";
import Logout from "../../images/logout.svg";
import { UserState } from "../../store/user/types";
import { AppState } from "../../store";
import getCardNumber from "../../utils/cardNumber";
import DeleteAccountModal from "../delete-account-modal";
import { getPaymentMethods } from "../../store/user/actions";
import { TOAST_TYPE, useToast } from "../../ui/toast";

const Header: FC = () => {
  const dispatch = useThunkDispatch();

  const user = useSelector<AppState, UserState>(state => state.user);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToast();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const response = await dispatch(getPaymentMethods());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast]);

  const [delAccountModalOpen, setDelAccountModalOpen] = useState<boolean>(
    false
  );

  const logout = useCallback(() => {
    dispatch(signOut());
    navigate("/");
  }, [dispatch]);

  return (
    <>
      <Wrapper>
        <TitleContainer>
          <Title>
            <FormattedMessage id="profile.home.title" />
          </Title>
        </TitleContainer>
        <Container>
          <ProfileContainer>
            <InfoContainer>
              <Label>
                <FormattedMessage id="signup.firstname.placeholder" />
              </Label>
              <Content>{user.firstName}</Content>
            </InfoContainer>
            <InfoContainer>
              <Label>
                <FormattedMessage id="signup.lastname.placeholder" />
              </Label>
              <Content>{user.lastName}</Content>
            </InfoContainer>
            <InfoContainer>
              <Label>
                <FormattedMessage id="signup.tel.placeholder" />
              </Label>
              <Content>{user.phoneNumber}</Content>
            </InfoContainer>
            <InfoContainer>
              <Label>
                <FormattedMessage id="signup.email.placeholder" />
              </Label>
              <Content>{user.email}</Content>
            </InfoContainer>
          </ProfileContainer>
          <ProfileContainer>
            <TitlePartContainer>
              <Title>
                <FormattedMessage id="profile.home.orders" />
              </Title>
            </TitlePartContainer>
            <TitlePartContainer>
              <Text>
                <FormattedMessage id="profile.home.cart.text" />
                <Link to="/cart" style={{ textDecoration: "none" }}>
                  <FormattedMessage id="profile.here" />
                </Link>
              </Text>
            </TitlePartContainer>
            <TitlePartContainer>
              <Text>
                <FormattedMessage id="profile.home.orders.text" />
                <Link to="/orders" style={{ textDecoration: "none" }}>
                  <FormattedMessage id="profile.here" />
                </Link>
              </Text>
            </TitlePartContainer>
          </ProfileContainer>
          <ProfileContainer>
            <TitlePartContainer>
              <Title>
                <FormattedMessage id="profile.payment" />
              </Title>
            </TitlePartContainer>
            <TitlePartContainer>
              <Text>
                <FormattedMessage id="profile.home.payment.text" />
                <Link to="/payment-methods" style={{ textDecoration: "none" }}>
                  <FormattedMessage id="profile.here" />
                </Link>
              </Text>
            </TitlePartContainer>
            {loading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <>
                {user.paymentMethods.map(payment => (
                  <ContentPartContainer key={payment.id}>
                    <>
                      <Icon>
                        <PaymentMethodIcon brand={payment.brand} />
                      </Icon>
                      <Text>{getCardNumber(payment.last4)}</Text>
                    </>
                  </ContentPartContainer>
                ))}
              </>
            )}
          </ProfileContainer>
          <ProfileContainer>
            <TitlePartContainer>
              <Title>
                <FormattedMessage id="addresses.title" />
              </Title>
            </TitlePartContainer>
            <TitlePartContainer>
              <Text>
                <FormattedMessage id="profile.home.addresses.text" />
                <Link to="/addresses" style={{ textDecoration: "none" }}>
                  <FormattedMessage id="profile.here" />
                </Link>
              </Text>
            </TitlePartContainer>
            {user.addresses.map(address => (
              <ContentPartContainer key={address.id}>
                <Text>{`${address.number} ${address.street}, ${address.postalCode} ${address.city}`}</Text>
              </ContentPartContainer>
            ))}
          </ProfileContainer>
          <ProfileContainer>
            <TitlePartContainer>
              <Title>
                <FormattedMessage id="profile.delAccount" />
              </Title>
            </TitlePartContainer>
            <TitlePartContainer>
              <Text>
                <FormattedMessage id="profile.home.delAccount.text" />
                <Link
                  onClick={(): void => setDelAccountModalOpen(true)}
                  to="#"
                  style={{ textDecoration: "none" }}
                >
                  <FormattedMessage id="profile.here" />
                </Link>
              </Text>
            </TitlePartContainer>
          </ProfileContainer>
          <Button
            onClick={logout}
            style={{ textDecoration: "none", backgroundColor: "#f5f5f5" }}
          >
            <LinkContainer>
              <SvgContainer>
                <Logout />
              </SvgContainer>
              <Text>
                <FormattedMessage id="profile.logout" />
              </Text>
            </LinkContainer>
          </Button>
        </Container>
      </Wrapper>
      <DeleteAccountModal
        closeModal={(): void => setDelAccountModalOpen(false)}
        onSuccess={(): void => console.log("... onSuccess")}
        open={delAccountModalOpen}
      />
    </>
  );
};

export default Header;
