import styled from "styled-components";
import Button from "../../../ui/button";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.p`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 16px;
  color: ${({ theme: { colors } }): string => colors.primary};
  padding: 0 10px;
  text-align: center;
`;

export const ButtonAction = styled(Button)`
  margin-bottom: 10px;
  min-width: 128px;
`;
