import styled from 'styled-components';
import { Theme } from '../../../theme/theme-provider';

export enum BUTTON_TYPE {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

interface Props {
  theme: Theme;
  type?: BUTTON_TYPE;
}

interface ContainerProps extends Props {
  disabled: boolean;
}

const getColor = ({ theme: { colors }, type }: Props): string | undefined => {
  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return colors.white;
    case BUTTON_TYPE.SECONDARY:
      return colors.secondary;
  }
};

export const getBackgroundColor = ({ theme: { colors }, type, disabled }: ContainerProps): string | undefined => {
  if (disabled) {
    return colors.grey;
  }

  switch (type) {
    case BUTTON_TYPE.PRIMARY:
      return colors.secondary;
    case BUTTON_TYPE.SECONDARY:
      return colors.white;
  }
};

export const Container = styled.div<ContainerProps>`
  height: 50px;
  padding-horizontal: 16px;
  border-radius: 15px;
  background-color: ${getBackgroundColor};
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Loader = styled.div`
  width: 16px;
  height: 16px;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Left = styled(IconContainer)`
  margin-right: 5px;
`;

export const Right = styled(IconContainer)`
  margin-left: 5px;
`;

export const Text = styled.span<Props>`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 12px;
  color: ${getColor};
`;

