import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import OneSignal from 'react-onesignal';

import { HttpResponse, post } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import {
  SET_PHONE_NUMBER,
  SIGN_IN,
  SIGN_OUT,
  AuthActionTypes,
  LoginResponse,
} from "./types";
import { createOneSignalProfile, setUser } from "../user/actions";
import { AppState } from "..";
import { User } from "../../types/user";
import { Login } from "../../types/login";

interface AuthResponse {
  id: string;
  reference: string;
  firstName: string;
  lastName: string;
  email: string;
  token: string;
  phoneNumber: string;
}

export const setPhoneNumber = (phoneNumber: string): AuthActionTypes => ({
  type: SET_PHONE_NUMBER,
  phoneNumber,
});

export const signIn = (token: string): AuthActionTypes => ({
  type: SIGN_IN,
  token,
});

export const signOut = (): AuthActionTypes => ({
  type: SIGN_OUT,
});

export const sendVerificationCode = ( 
  phoneNumber: string
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await post<{}>(
      "/customers/phone/login/start",
      { phoneNumber },
      getState()
    );

    dispatch(setPhoneNumber(phoneNumber));

    return response;
  } catch (error) {
    return error;
  }
};

export const verifyNumber = (route: string) => (
  phoneNumber: string,
  code: string
): ThunkAction<Promise<HttpResponse<User>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<User>> => {
  try {
    const response = await post<User>(
      `/customers/phone/${route}/verify`,
      { phoneNumber, code },
      getState()
    );
    if (response.parsedBody) {
      const {
        token,
        firstName,
        lastName,
        email,
        phoneNumber,
        reference,
      } = response.parsedBody;

      dispatch(signIn(token));
      dispatch(setUser(reference, firstName, lastName, email, phoneNumber));
    }

    return response;
  } catch (error) {
    return error;
  }
};

export const sendSignupVerificationCode = (
  phoneNumber: string,
  firstName: string,
  lastName: string,
  email: string,
  newsletterValue: boolean
): ThunkAction<Promise<HttpResponse<{}>>, AppState, {}, AnyAction> => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<HttpResponse<{}>> => {
  try {
    const response = await post<{}>(
      "/customers/phone/signup/start",
      {
        firstName,
        lastName,
        phoneNumber,
        email,
        newsletterOptIn: newsletterValue.toString(),
      },
      getState()
    );

    dispatch(setPhoneNumber(phoneNumber));

    return response;
  } catch (error) {
    return error;
  }
};

export const login = ({ email, password }: Login) => async (
  dispatch: ReduxDispatch,
  getState: () => AppState
): Promise<LoginResponse> => {
  let response!: HttpResponse<AuthResponse>;

  try {
    response = await post("/customers/login", { email, password }, getState());
  } catch (e) {
    const errorText = 401 === e.status ? "invalidCredentials" : "serverError";

    return {
      success: false,
      error: errorText,
    };
  }

  if (response && response.parsedBody) {
    const {
      reference,
      token,
      firstName,
      lastName,
      email,
      phoneNumber,
    } = response.parsedBody;

    dispatch(signIn(token));
    dispatch(setUser(reference, firstName, lastName, email, phoneNumber));
  }

  return {
    success: true,
  };
};
