import React, { FC, ReactNode, CSSProperties } from "react";

import { Container, Loader, Text, BUTTON_TYPE } from "./style";
import { Left, Right } from "../button-round/style";
import Spinner from "../../images/spinner-white.svg";

export { BUTTON_TYPE };

interface Props {
  children: string | JSX.Element;
  loading?: boolean;
  type?: BUTTON_TYPE;
  left?: ReactNode;
  right?: ReactNode;
  style?: CSSProperties;
  disabled?: boolean;
  onClick: () => void;
}

const Button: FC<Props> = ({
  children,
  disabled = false,
  loading = false,
  type = BUTTON_TYPE.PRIMARY,
  left,
  right,
  style,
  ...props
}) => (
  <Container
    style={style}
    disabled={disabled || loading}
    type={type}
    {...props}
  >
    {loading ? (
      <Loader>
        <Spinner />
      </Loader>
    ) : (
      <>
        {left && <Left>{left}</Left>}
        <Text type={type}>{children}</Text>
        {right && <Right>{right}</Right>}
      </>
    )}
  </Container>
);

export default Button;
