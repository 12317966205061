import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Profile from "../components/profile";

const IndexPage: FC = () => (
  <Layout header menuState="profile" requireAuth>
    <SEO title="Accueil" />
    <Profile />
  </Layout>
);

export default IndexPage;
